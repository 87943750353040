var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"392f4b71e6d7d338c2720476dd70317c8172e8da"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Config from '@/config/Config';
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { replayIntegration } from '@sentry/nextjs';

console.log('[prefare] setting up sentry client config', Config);
Sentry.init({
    dsn: 'https://ecc1206849004663bca0f38270eb453f@o742259.ingest.sentry.io/5786815',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    sampleRate: 1,
    enabled: Config.SENTRY.SENTRY_ENABLED,
    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    maxValueLength: 500,
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [replayIntegration({ maskAllText: false, blockAllMedia: false, maskAllInputs: false })],
});

// console.log('[prefare] sentry env', Sentry.getCurrentHub().getScope().getSession());
